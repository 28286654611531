<template>
	<div id="changeseason-wrapper">
		<h1>
			{{ year }}赛季{{ seasonStatus ? "已开始" : "已结束" }}<br>本赛季选项{{
				selectStatus ? "已" : "未"
			}}开始
		</h1>
		<el-button
			type="primary"
			class="changeseason-btn"
			@click="startSeasonOpen()"
			v-if="!seasonStatus"
			>开始赛季</el-button
		>
		<el-button
			type="primary"
			class="changeseason-btn"
			@click="startSelect()"
			v-if="!selectStatus && seasonStatus"
			>开始选项</el-button
		>
		<el-button
			type="danger"
			class="changeseason-btn"
			@click="endSelect()"
			v-if="selectStatus"
			>结束选项</el-button
		>
		<p />
		<el-button
			type="danger"
			class="changeseason-btn"
			@click="endSeasonOpen()"
			v-if="seasonStatus"
			>结束赛季</el-button
		>
	</div>
</template>

<style scoped>
#changeseason-wrapper {
	position: relative;
	margin-left: auto;
}
.changeseason-btn {
	position: relative;
	margin-left: auto;
	margin-right: 5px;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "ChangeSeason",
	data() {
		return {
			year: 2021,
			selectStatus: 0,
			seasonStatus: 0,
		};
	},
	beforeMount() {
		axios({
			url: "/api/game/getyear",
			method: "GET",
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
			data: "",
		})
			.then((res) => {
				// console.log(res);
				this.year = res.data.year;
				this.selectStatus = res.data.selectstatus;
				this.seasonStatus = res.data.seasonstatus;
			})
			.catch((err) => {
				this.messageBox(
					"修改失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
		},
		startSeasonOpen() {
			this.$prompt(
				"请输入您要开启的赛季年份，上一赛季为" + String(this.year),
				"提示",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputPattern: /20[0-9]{2}/,
					inputErrorMessage: "请输入2000-2099之间的年份",
				}
			).then(({ value }) => {
				this.$confirm("此操作将无法撤回, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						this.startSeason(value);
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消操作",
						});
					});
			});
		},
		startSelectOpen() {
			this.$confirm("此操作将无法撤回, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.startSelect();
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		startSeason(yearT) {
			if (this.seasonStatus != 0) {
				this.messageBox("赛季已开始，不需要开启赛季", "error");
				return;
			}
			axios({
				url: "/api/admin/game/startyear",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
				data: qs.stringify({
					year: yearT,
				}),
			})
				.then(async () => {
					// console.log(res);
					this.messageBox("已开启新赛季", "success");
					this.year = yearT;
					this.seasonStatus = 1;
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					console.log(err.response);
					this.messageBox("开启赛季失败，"
						+ err?.response?.data?.Message ?? "网络断开或神秘错误", "error");
				});
		},
		startSelect() {
			if (this.selectStatus != 0) {
				this.messageBox("选项已开始，不需要开启选项", "error");
				return;
			}
			axios({
				url: "/api/admin/game/startselect",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
			})
				.then(async () => {
					// console.log(res);
					this.messageBox("已开启选项", "success");
					this.selectStatus = 1;
					await this.sleep(250);
					// this.$router.go(0);
				})
				.catch((err) => {
					console.log(err.response);
					this.messageBox("开启选项失败"
						+ err?.response?.data?.Message ?? "网络断开或神秘错误", "error");
				});
		},
		endSeasonOpen() {
			this.$prompt(
				'请输入 "结束赛季" 四个汉字，结束赛季操作将无法撤回',
				"确认",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputPattern: /^结束赛季$/,
					inputErrorMessage: '请输入 "结束赛季" 四个汉字',
				}
			).then((_) => {
				if (_.value === "结束赛季") {
					this.$confirm("此操作将无法撤回, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
						.then(() => {
							this.endSeason();
						})
						.catch(() => {
							this.$message({
								type: "info",
								message: "已取消操作",
							});
						});
				} else {
					this.$message({
						type: "info",
						message: "请输入“结束赛季”以确认操作",
					});
				}
			});
		},
		endSelectOpen() {
			this.$confirm("此操作将无法撤回, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.endSelect();
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		endSeason() {
			if (this.seasonStatus == 0) {
				this.messageBox("赛季未开始，不需要结束赛季", "error");
				return;
			}
			axios({
				url: "/api/admin/game/endyear",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
			})
				.then(async () => {
					// console.log(res);
					this.messageBox("已结束赛季", "success");
					this.seasonStatus = 0;
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					console.log(err.response);
					this.messageBox("结束赛季失败"
						+ err?.response?.data?.Message ?? "网络断开或神秘错误", "error");
				});
		},
		endSelect() {
			if (this.selectStatus == 0) {
				this.messageBox("选项未开始，不需要结束选项", "error");
				return;
			}
			axios({
				url: "/api/admin/game/endselect",
				method: "POST",
				headers: {
					"content-type": "application/x-www-form-urlencoded",
				},
			})
				.then(async () => {
					// console.log(res);
					this.messageBox("已结束选项", "success");
					this.selectStatus = 0;
					await this.sleep(250);
					// this.$router.go(0);
				})
				.catch((err) => {
					console.log(err.response);
					this.messageBox("结束选项失败"
						+ err?.response?.data?.Message ?? "网络断开或神秘错误", "error");
				});
		},
	},
};
</script>